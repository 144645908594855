var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports.none = Object.create({
  value: function () {
    throw new Error("Called value on none");
  },
  isNone: function () {
    return true;
  },
  isSome: function () {
    return false;
  },
  map: function () {
    return exports.none;
  },
  flatMap: function () {
    return exports.none;
  },
  filter: function () {
    return exports.none;
  },
  toArray: function () {
    return [];
  },
  orElse: callOrReturn,
  valueOrElse: callOrReturn
});

function callOrReturn(value) {
  if (typeof value == "function") {
    return value();
  } else {
    return value;
  }
}

exports.some = function (value) {
  return new Some(value);
};

var Some = function (value) {
  (this || _global)._value = value;
};

Some.prototype.value = function () {
  return (this || _global)._value;
};

Some.prototype.isNone = function () {
  return false;
};

Some.prototype.isSome = function () {
  return true;
};

Some.prototype.map = function (func) {
  return new Some(func((this || _global)._value));
};

Some.prototype.flatMap = function (func) {
  return func((this || _global)._value);
};

Some.prototype.filter = function (predicate) {
  return predicate((this || _global)._value) ? this || _global : exports.none;
};

Some.prototype.toArray = function () {
  return [(this || _global)._value];
};

Some.prototype.orElse = function (value) {
  return this || _global;
};

Some.prototype.valueOrElse = function (value) {
  return (this || _global)._value;
};

exports.isOption = function (value) {
  return value === exports.none || value instanceof Some;
};

exports.fromNullable = function (value) {
  if (value == null) {
    return exports.none;
  }

  return new Some(value);
};

export default exports;
export const none = exports.none,
      some = exports.some,
      isOption = exports.isOption,
      fromNullable = exports.fromNullable;